<template>
    <div class="businessList">
        <div class="derive">
            <el-button size="small" type="primary" @click="createCircles()">添加</el-button>
        </div>
        <base-table :columns="circleTypeList" :data="tableData" :pagination="searchForm" :total="total" @getData="initData"
            :loading="loading" :isPaginationShow="true" wrapperHeight="calc(100% - 45px)">
            <el-table-column slot="status" label="状态" align="center" width="180">
                <template slot-scope="scope">
                    <div>{{ scope.row.status == 1 ?'可用':'不可用' }}</div>
                </template>
            </el-table-column>
            <el-table-column slot="options" label="操作" align="center" width="180" fixed="right">
                <template slot-scope="scope">
                    <el-button type="text" size="small"
                        @click="editBusiness(scope.row)">修改</el-button>
                    <el-button type="text" size="small"
                        @click="delBusiness(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </base-table>
        <circleTypeModel ref="circleModel"></circleTypeModel>
    </div>
</template>
<script>
import columns from "./columns";
import { getCircleTypeList } from '@/api/car'
import circleTypeModel from './circle-type-modal.vue'
export default {
    components: {
        circleTypeModel: circleTypeModel
    },
    mounted() {
        this.initData()
    },
    data() {
        return {
            ...columns,
            total: 0,
            loading: false,
            tableData: [],
            searchForm: {
                page: 1,
                pageCount: 10
            },
        }
    },
    methods: {
        async initData() {
            const { code, data } = await getCircleTypeList(this.searchForm)
            if (code == 200) {
                this.tableData = data.dataList
                this.total = data.count
            }
        },
        createCircles() {
            this.$refs.circleModel.isShow('new')
        },
        editBusiness(info) {
            this.$refs.circleModel.isShow('edit', info)
        }
    }
}
</script>
<style scoped lang="scss">
.businessList {
    .derive {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
    }
}
</style>