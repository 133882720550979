<template>
  <div>
    <el-dialog :title="isEdit ? '修改圈子类型' : '新建圈子类型'" width="770px" :close-on-click-modal="false"
      :visible.sync="dialogFormVisible" custom-class="customW" :before-close="handleClose">
      <el-form ref="cirlceForm" :model="cirlceForm" label-width="200px" size="small" :rules="rules">
        <el-form-item label="圈子类型" label-width="100px" prop="circleTypeName" style="margin-bottom: 15px">
          <el-input v-model="cirlceForm.circleTypeName" clearable placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createCircleType, modifyCircleType } from '@/api/car'
export default {
  name: "operating-record",
  mounted() {
  },
  data() {
    return {
      isEdit: false,
      dialogFormVisible: false,
      cirlceForm: {
        circleTypeName: '',
        circleTypeId: ''
      },
      rules: {
        circleTypeName: [
          { required: true, message: "请输入圈子类型名称", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    async isShow(type, info) {
      this.dialogFormVisible = true;
      if (type == "edit") {
        this.isEdit = true;
        this.cirlceForm.circleTypeName = info.circleTypeName ? info.circleTypeName : ''
        this.cirlceForm.circleTypeId = info.circleTypeId ? info.circleTypeId : ''

      } else {
        this.isEdit = false;
      }
    },
    handleClose(done) {
      done();
      this.close();
    },
    close() {
      this.dialogFormVisible = false;
      this.$refs.cirlceForm.resetFields();
      this.cirlceForm.fileList = []
    },
    confirm() {
      this.$refs['cirlceForm'].validate(async (valid) => {
        if (valid) {
          console.log(this.cirlceForm)
          // this.isEdit 
          let res = this.isEdit ? await modifyCircleType(this.cirlceForm) : await createCircleType(this.cirlceForm)
          if (res.code == 200) {
            this.close()
            this.$parent.initData()
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .customW {
  border-radius: 10px !important;

  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;

    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }

  .classification {
    margin: 10px 40px;

    .content-form {
      margin: 0px 10px;
    }
  }

  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>